import { Fragment, useState } from "react";
import Responsive from 'src/components/Responsive';
import { Page, sendCategoryClick } from "src/modules/ubercatalog";
import { MIN_DESKTOP_WIDTH } from 'src/styles/media';
import Stack from "src/ui-kit/Stack/Stack";
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { Typography } from 'src/ui-kit/Typography';
import { useTheme } from "src/ui-kit/theme";
import { getIsServer } from 'src/utils/system/getIsServer';
import { useLocalize } from "../../../../services/localize/useLocalize";
import IconFont from '../../../../ui-kit/IconFont';
import CategoriesMenu from '../CategoriesMenu/CategoriesMenu';
import CategoriesMobileMenu from "../CategoriesMobileMenu/CategoriesMobileMenu";
import { CategoriesMenuButtonStyled } from "./CategoriesMenuButton.styles";

interface Props {
  pageForAnalytics?: Page;
}

const CategoriesMenuButton = (props: Props) => {
  const {
    pageForAnalytics = 'landing',
  } = props;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const theme = useTheme();
  const localize = useLocalize();

  const isServer = getIsServer();
  const mobileMenuShouldBeVisible = !isServer && window.innerWidth < MIN_DESKTOP_WIDTH;

  const closeMenu = () => setMenuIsOpen(false);
  const toggleMenu = () => setMenuIsOpen(!menuIsOpen);

  const handleLinkClick = (categoryId: string) => {
    sendCategoryClick({
      place: 'category_tree',
      page: pageForAnalytics,
      category_id: categoryId,
    });
    closeMenu();
  };

  return (
    <CategoriesMenuButtonStyled
      theme={theme}
      className='CategoriesMenuButton'
    >
      <button
        className="CategoriesMenuButton__inner"
        data-testid="categoriesMenuButton"
        data-marker="Categories menu button"
        onClick={toggleMenu}
      >
        <IconFont
          name='categories'
          size='18px'
          color='inherit'
          sbs={{ mr: { desktopTablet: '11px', phone: '8px' } }}
        />

        <Responsive displayOn='phone' displayNoneMode>
          <Typography
            variant='text5'
            color='inherit'
          >
            {localize('catalog_short')}
          </Typography>
        </Responsive>

        <Responsive displayOn='tabletDesktop' displayNoneMode>
          <Stack
            justifyContent='center'
            alignItems='center'
          >
            <Typography
              variant='text5'
              color='inherit'
            >
              {localize('catalog')}
            </Typography>

            <IconFont
              name={menuIsOpen ? 'caret-top' : 'caret-bottom'}
              size='10px'
              color='inherit'
              sbs={{ ml: '7px' }}
            />
          </Stack>
        </Responsive>
      </button>


      {
        menuIsOpen && (
          <Fragment>
            {mobileMenuShouldBeVisible
              ? <CategoriesMobileMenu onClose={closeMenu} onItemClick={handleLinkClick} />
              : <CategoriesMenu onItemClick={handleLinkClick} />
            }

            <StylishBox
              elementProps={{
                onClick: closeMenu,
              }}
              sbs={{
                position: 'fixed',
                left: '0',
                top: '0',
                zIndex: '4',
                width: '100%',
                height: '100%',
              }}
            />
          </Fragment>
        )
      }
    </CategoriesMenuButtonStyled>
  );
};

export default CategoriesMenuButton;
