import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const CategoriesMenuHeaderStyled = styled.div<StyledThemeProps>(({ theme }) => `
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 0 10px 0 15px;
  border-bottom: 1px solid ${theme.base.color.gray200};

  .CategoriesMenuHeader__title {
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .CategoriesMenuHeader__title:first-letter {
    text-transform: uppercase;
  }
  .CategoriesMenuHeader__container {
    display: flex;
    flex-shrink: 0;
    margin-left: 10px;
    width: 44px;
    height: 44px;
    align-items: center;
    justify-content: center;
  }
`);
