import styled from '@emotion/styled';
import { StyledThemeProps } from 'src/ui-kit/theme/types';

export const CategoriesMenuListItemStyled = styled.li<StyledThemeProps>(({ theme }) => `
  font-size: 14px;
  list-style: none;

  .CategoriesMenuListItem__link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 9px 28px 9px 16px;
    height: 42px;
    transition: 0.2s background-color ease-in-out;
  }
  .CategoriesMenuListItem__link_withChildren:after {
    content: "";
    display: block;
    width: 7px;
    height: 7px;
    position: absolute;
    right: 14px;
    border-right: 1.5px solid ${theme.base.color.gray400};
    border-bottom: 1.5px solid ${theme.base.color.gray400};
    transform: rotate(-45deg);
  }
  .CategoriesMenuListItem__link_active,
  .CategoriesMenuListItem__link:hover {
    background-color: ${theme.base.color.gray100};
  }
  .CategoriesMenuListItem__link_active:after,
  .CategoriesMenuListItem__link:hover:after {
    color: ${theme.base.color.dark};
  }
  .CategoriesMenuListItem__icon {
    flex-shrink: 0;
    display: flex;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    align-items: center;
    justify-content: center;
  }
  .CategoriesMenuListItem__icon svg {
    display: block;
    width: 100%;
  }
  .CategoriesMenuListItem__icon path {
    transition: 0.2s fill ease-in-out;
  }
  .CategoriesMenuListItem__title {
    color: #404844;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .CategoriesMenuListItem__title:first-letter {
    text-transform: uppercase;
  }
`);

